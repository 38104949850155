<template>
  <div class="carousel-container">
    <div class="activity-action-area">
      <a-button @click="openCreateDialog">新增廣告</a-button>
    </div>
    <div class="activity-list-area">
      <a-table :dataSource="activities" :columns="columns" rowKey="id">
        <!-- <template #custonIdTmp="{ text }">
          <span>
            {{ convertCustomId(text) }}
          </span>
        </template>
        <template #tmpModifyTime="{ text }">
          {{ convertTimeFormat(text) }}
        </template>
        <template #tmpRemark="{ text }">
          <a-popover title="" trigger="click">
            <template #content>
              <div style="max-width: 200px">
                <p>{{ text }}</p>
              </div>
            </template>
            <a>{{ convertRemark(text) }}</a>
          </a-popover>
        </template> -->
        <template #tmpModifyTime="{ text }">
          {{ convertTimeFormat(text) }}
        </template>
        <template #openModeTmp="{ text }">
          <span>
            {{ convertOpenMode(text) }}
          </span>
        </template>
        <template #imageTmp="{ text }">
          <a-image :width="150" :src="getImgUrl(text)" />
        </template>
        <template #operation="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              <BarsOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <div @click="openEditDialog(record)">編輯</div>
                </a-menu-item>
                <a-menu-item>
                  <div @click="clickDeleteActivity(record)">刪除</div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="carousel-modal-area">
      <a-modal
        class="carousel-detail-modal"
        v-model:visible="isShowDetail"
        :title="modalTitle"
        :closable="true"
      >
        <template #footer>
          <div>
            <!-- <a-button
              class="btn-cancel-gray"
              key="cancel"
              @click="clickCancelModify"
            >
              取消
            </a-button> -->
            <a-button
              class="btn-confirm-red"
              key="submit"
              @click="clickConfirmModify"
              :loading="isSaveloading"
            >
              確認
            </a-button>
          </div>
        </template>
        <a-form
          ref="carouselFormRef"
          :model="carouselDetail"
          :label-col="detailLabelCol"
          :wrapper-col="detailWrapperCol"
          :rules="detailRules"
        >
          <a-form-item label="順序" name="order">
            <a-input v-model:value="carouselDetail.order" />
          </a-form-item>
          <a-form-item label="行銷活動名稱" name="name">
            <a-input v-model:value="carouselDetail.name" />
          </a-form-item>
          <a-form-item label="開啟方式" name="openMode">
            <a-select
              :allowClear="false"
              v-model:value="carouselDetail.openMode"
            >
              <a-select-option key="external" value="external">
                外開瀏覽器
              </a-select-option>
              <a-select-option key="internal" value="internal">
                App內部瀏覽器
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="網址" name="webUrl">
            <a-input v-model:value="carouselDetail.webUrl" />
          </a-form-item>
          <a-form-item label="圖片" name="picture">
            <a-upload
              :action="uploadApi"
              name="dashboardImg"
              list-type="picture"
              v-model:file-list="carouselDetail.fileList"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="carouselDetail.fileList.length < 1">
                <a-button>
                  <upload-outlined></upload-outlined>
                  Upload
                </a-button>
                <!-- <plus-outlined />
                <div class="ant-upload-text">Upload</div> -->
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal v-model:visible="confirmDeleteVisible" title="">
      <template #footer>
        <a-button class="btn-cancel-gray" @click="onClickDeleteCancel">
          取消
        </a-button>
        <a-button class="btn-confirm-red" @click="onClickDeleteOk">
          確認
        </a-button>
      </template>
      <!-- <p v-text="confirmDeleteMsg"></p> -->
      <div class="text-wrapper" style="white-space: pre-wrap">
        是否確認刪除？
      </div>
    </a-modal>
    <a-modal v-model:visible="isOverLimit" title="">
      <template #footer>
        <a-button class="btn-confirm-red" @click="onClickLimitWarningOk">
          確認
        </a-button>
      </template>
      <!-- <p v-text="confirmDeleteMsg"></p> -->
      <div class="text-wrapper" style="white-space: pre-wrap">
        輪播廣告已達新增上限
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { UploadOutlined, BarsOutlined } from '@ant-design/icons-vue'
  import { defineComponent, ref, onMounted, computed } from 'vue'
  import { useStore } from 'vuex'
  import dayjs from 'dayjs'

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => resolve(reader.result)

      reader.onerror = (error) => reject(error)
    })
  }

  export default defineComponent({
    components: {
      UploadOutlined,
      BarsOutlined,
    },

    setup() {
      // 活動最大上限 20
      const MAX_ACTIVITY_COUNT = 20
      const store = useStore()
      const previewVisible = ref(false)
      const previewImage = ref('')
      const isShowDetail = ref(false)
      const carouselFormRef = ref(null)
      const carouselDetail = ref({
        id: null,
        order: null,
        name: '',
        openMode: 'internal',
        webUrl: '',
        fileList: [],
      })
      const detailLabelCol = ref({ span: 6 })
      const detailWrapperCol = ref({ span: 18 })
      const detailRules = ref({
        order: [{ required: true, message: '請輸入順序', trigger: 'blur' }],
        name: [{ required: true, message: '請輸入名稱', trigger: 'blur' }],
        webUrl: [
          {
            required: true,
            message: '請輸入網址，必須 https:// 開頭',
            trigger: 'blur',
          },
        ],
      })
      const isSaveloading = ref(false)
      const columns = ref([
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   key: 'id',
        // },
        {
          title: '順序',
          dataIndex: 'order',
          key: 'order',
          width: '5%',
        },
        {
          title: '行銷活動名稱',
          dataIndex: 'name',
          key: 'name',
          width: '20%',
        },
        {
          title: '圖片',
          dataIndex: 'imgPath',
          key: 'imgPath',
          slots: {
            customRender: 'imageTmp',
          },
        },
        {
          title: '開啟方式',
          dataIndex: 'openMode',
          key: 'openMode',
          width: '15%',
          slots: {
            customRender: 'openModeTmp',
          },
        },
        {
          title: '開啟網址',
          dataIndex: 'webUrl',
          width: '30%',
          key: 'webUrl',
        },
        {
          title: '編輯時間',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: '10%',
          slots: {
            customRender: 'tmpModifyTime',
          },
        },
        {
          title: '',
          key: 'operation',
          width: '5%',
          slots: {
            customRender: 'operation',
          },
        },
      ])
      const confirmDeleteVisible = ref(false)
      let isCreatedMode = true
      let prepareDeleteActivity = null
      const isOverLimit = ref(false)
      const modalTitle = ref('新增輪播廣告')
      const uploadApi = ref(process.env.VUE_APP_BASE_URL + '/upload')

      const handleCancel = () => {
        previewVisible.value = false
      }

      const handlePreview = async (file) => {
        console.log('-- handlePreview')
        console.log(carouselDetail.value.fileList)
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj)
        }

        previewImage.value = file.url || file.preview
        previewVisible.value = true
      }

      const handleChange = ({ fileList: newFileList }) => {
        console.log('-- handleChange')
        console.log(carouselDetail.value.fileList)
        console.log(newFileList)
        if (newFileList.length > 0) {
          let imgPath = newFileList[0]?.response?.data?.path || ''
          imgPath = imgPath.replace('public', '')
          carouselDetail.value.fileList[0].imgPath = imgPath
          carouselDetail.value.fileList[0].originalImgName =
            newFileList[0]?.response?.data?.originalname || ''
        }
        // carouselDetail.value.fileList.value = newFileList
      }

      // Vuex action mapping
      const getActivityList = () => store.dispatch('activity/getActivityList')
      const editActivity = (postBody) =>
        store.dispatch('activity/editActivity', postBody)
      const createActivity = (postBody) =>
        store.dispatch('activity/createActivity', postBody)
      // const getActivity = (id) => store.dispatch('activity/getActivity',id)
      const deleteActivity = (id) =>
        store.dispatch('activity/deleteActivity', id)

      // Vuex state mapping
      //const activities = computed(() => store.state.activity.activities)

      // Vuex state getter
      // const companies = computed(() => store.getters["companies/getAvailableCompanies"]);
      const activities = computed(() => store.getters['activity/activities'])

      onMounted(async () => {
        getActivityList()
      })

      const openEditDialog = (record) => {
        if (carouselFormRef.value) {
          carouselFormRef.value.clearValidate()
        }
        cleanCarouselDetail()
        carouselDetail.value = {
          id: record?.id || undefined,
          order: record?.order ? record?.order.toString() : 0,
          name: record?.name || '',
          openMode: record?.openMode || 'internal',
          webUrl: record?.webUrl || '',
          fileList: [
            {
              uid: record?.id || -1,
              id: record?.id || -1,
              name: record?.originalImgName || '',
              status: record?.originalImgName ? 'done' : 'error',
              url: getImgUrl(record?.imgPath),
              originalImgName: record?.originalImgName || '',
              imgPath: record?.imgPath || '',
            },
          ],
        }
        modalTitle.value = '修改輪播廣告'
        isCreatedMode = false
        isShowDetail.value = true
      }

      const openCreateDialog = () => {
        console.log('openCreateDialog')
        console.log(activities.value.length)
        if (activities.value && activities.value.length >= MAX_ACTIVITY_COUNT) {
          isOverLimit.value = true
          return
        }
        if (carouselFormRef.value) {
          carouselFormRef.value.clearValidate()
        }
        cleanCarouselDetail()
        modalTitle.value = '新增輪播廣告'
        isCreatedMode = true
        isShowDetail.value = true
      }

      // const clickCancelModify = () => {
      //   isShowDetail.value = false
      // }

      const clickConfirmModify = async () => {
        try {
          await carouselFormRef.value.validate()
          const orderNum = parseInt(carouselDetail.value?.order, 10)
          const postBody = {
            order: orderNum || 0,
            name: carouselDetail.value?.name,
            openMode: carouselDetail.value?.openMode,
            webUrl: carouselDetail.value?.webUrl,
            originalImgName:
              carouselDetail.value?.fileList?.[0]?.originalImgName || '',
            imgPath: carouselDetail.value?.fileList?.[0]?.imgPath || '',
          }
          isSaveloading.value = true
          if (isCreatedMode === true) {
            await createActivity(postBody)
          } else {
            postBody.id = carouselDetail.value?.id
            await editActivity(postBody)
          }
          isSaveloading.value = false
          cleanCarouselDetail()
          isShowDetail.value = false
        } catch (e) {
          console.log(e)
          isSaveloading.value = false
          isShowDetail.value = true
        }
      }

      const cleanCarouselDetail = () => {
        carouselDetail.value = {
          id: null,
          order: null,
          name: '',
          openMode: 'internal',
          webUrl: '',
          fileList: [],
        }
      }

      const clickDeleteActivity = async (record) => {
        prepareDeleteActivity = record
        confirmDeleteVisible.value = true
      }

      const convertOpenMode = (text) => {
        if (text === 'external') {
          return '外開瀏覽器'
        } else if (text === 'internal') {
          return 'App內部瀏覽器'
        } else {
          return ''
        }
      }

      const getImgUrl = (imgPath) => {
        return process.env.VUE_APP_IMG_BASE_URL + imgPath
      }

      const onClickDeleteCancel = () => {
        prepareDeleteActivity = null
        confirmDeleteVisible.value = false
      }

      const onClickDeleteOk = async () => {
        await deleteActivity(prepareDeleteActivity.id)
        prepareDeleteActivity = null
        confirmDeleteVisible.value = false
      }

      const convertTimeFormat = (text) => {
        if (text) {
          return dayjs(text).format('YYYY/MM/DD hh:mm')
        } else {
          return ''
        }
      }

      const onClickLimitWarningOk = () => {
        isOverLimit.value = false
      }

      return {
        previewVisible,
        previewImage,
        isShowDetail,
        carouselFormRef,
        carouselDetail,
        detailLabelCol,
        detailWrapperCol,
        detailRules,
        isSaveloading,
        activities,
        columns,
        confirmDeleteVisible,
        isOverLimit,
        modalTitle,
        uploadApi,

        handleCancel,
        handlePreview,
        handleChange,
        openEditDialog,
        openCreateDialog,
        clickDeleteActivity,
        // clickCancelModify,
        clickConfirmModify,
        convertOpenMode,
        convertTimeFormat,
        getImgUrl,
        onClickDeleteCancel,
        onClickDeleteOk,
        onClickLimitWarningOk,
      }
    },
  })
</script>

<style lang="less" scoped>
  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 100px;
    color: #666;
  }

  .carousel-container {
    .activity-action-area {
      padding: 10px 0px 10px 0px;
    }
    // .carousel-modal-area {
    //   // .carousel-form {

    //   // }
    // }
  }
  .btn-confirm-red {
    width: 135px;
    background: #ae402f;
    border-radius: 5px;
  }
  .btn-cancel-gray {
    width: 135px;
    background: #9c9b9b;
    border-radius: 4px;
  }
</style>
