import { render } from "./index.vue?vue&type=template&id=99941528&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=99941528&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-99941528"
/* hot reload */
if (module.hot) {
  script.__hmrId = "99941528"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('99941528', script)) {
    api.reload('99941528', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=99941528&scoped=true", () => {
    api.rerender('99941528', render)
  })

}

script.__file = "src/views/activity/index.vue"

export default script